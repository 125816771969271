import React, { useState } from 'react';
import { equals, cond, always, isNil } from 'ramda';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { map, reduce, reverse, prepend } from 'ramda';
import { InView } from 'react-intersection-observer';
import Heading from '@greenberry/salal/components/Heading';
import Section from '@greenberry/salal/components/Section';
import Introduction from '@greenberry/salal/components/Introduction';
import Text from '@greenberry/salal/components/Text';
import Button from '@greenberry/salal/components/Button';
import Card from '@greenberry/salal/components/Card';
import Explanation from '@greenberry/salal/components/Explanation';
import Icon from '@greenberry/salal/components/Icon';
import ImageInfoBlock from '@greenberry/salal/components/ImageInfoBlock';
import LineChart from '@greenberry/salal/components/Charts/Line';
import GroupedBarChart from '@greenberry/salal/components/Charts/GroupedBar';
import Legend from '@greenberry/salal/components/Legend';
import CardToggle from '@greenberry/salal/components/CardToggle';
import theme from '@greenberry/salal/theme';

import chartTitleGenerator from '../../../utils/chartTitleGenerator';
import formatDataSource from '../../../utils/formatDataSource';
import isMobile from '../../../utils/isMobile';
import notNil from '../../../utils/notNil';
import parseTooltip from '../../../utils/parseTooltip';
import { categoriesStudentCount } from '../../../constants/studentCategories';

import SlideIntoView from '../../../components/SlideIntoView';
import PartnershipLayout from '../../../components/PartnershipLayout';
import ThemeFooter from '../../../components/ThemeFooter';
import StudentCountTick from '../../../components/StudentCountTick';

import { formatStudentCount, formatParticipationRate } from './utils';
import getLatestYear from '../../../utils/getLatestYear';

const indicators = [
  {
    name: 'studentCount',
    Chart: GroupedBarChart,
    xKey: 'label',
    unit: 'leerlingen',
    width: 600,
    height: 600,
    sideNavData: {
      name: 'Aantal Leerlingen',
    },
  },
  {
    name: 'participationRate',
    Chart: GroupedBarChart,
    xKey: 'label',
    unit: 'percentage',
    height: 450,
    sideNavData: {
      name: 'Deelnamepercentage',
    },
  },
];

const formatStudentCountLegend = indicator =>
  indicator.map(({ label }, i) => ({
    label,
    color: theme.color(`chart.${i + 1}`),
  }));
const formatParticipationRateLegend = (
  indicator,
  partnershipName,
  selectedView
) =>
  selectedView === 'overview'
    ? [
        { label: partnershipName, color: theme.color('chart.1') },
        { label: 'Landelijk gemiddelde', color: theme.color('pattern.1') },
      ]
    : indicator.map(({ label, ...args }, i) => ({
        label,
        color: theme.color(`chart.${i + 1}`),
        ...args,
      }));

const formatLegendItems = (indicator, name, partnershipName, selectedView) =>
  cond([
    [equals('studentCount'), always(formatStudentCountLegend)],
    [equals('participationRate'), always(formatParticipationRateLegend)],
  ])(name)(indicator, partnershipName, selectedView);

const Students = ({
  pageContext: {
    dato,
    partnership,
    copy,
    sector,
    nationalParticipationRate,
    ...pageContext
  },
}) => {
  const _partnership = Object.assign({}, dato, partnership);
  const { infoBlockTitle, infoBlockBody, heading, lead, intro } = copy;
  const [selectedViews, setSelectedViews] = useState(
    reduce((acc, { name }) => ({ [name]: 'overview', ...acc }), {}, indicators)
  );

  const { infoImage, ...dataSources } = useStaticQuery(graphql`
    query {
      studentCount: datoCmsDataSource(indicator: { eq: "Aantal leerlingen" }) {
        source
        date
      }
      participationRate: datoCmsDataSource(
        indicator: { eq: "Deelname percentage" }
      ) {
        source
        date
      }
      infoImage: file(relativePath: { eq: "leerlingen.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const data = {
    participationRate: formatParticipationRate(
      _partnership.indicators.participationRate,
      nationalParticipationRate.data,
      sector
    ),
    studentCount: formatStudentCount(
      _partnership.indicators.studentCount,
      sector
    ),
  };

  const colors = {
    studentCount: reduce(
      (acc, curr) => ({ ...acc, [curr.label]: curr.color }),
      {},
      categoriesStudentCount[sector]
    ),
  };

  const setSelectedView = (indicator, view) => () => {
    const clone = Object.assign({}, selectedViews);
    clone[indicator] = view;
    setSelectedViews(clone);
  };

  const introductionOptions = {
    name: 'Introductie',
    id: 'introductie',
  };

  const sideNavOptions = indicators.map(({ name, sideNavData }) => ({
    name: sideNavData.name,
    id: `${sideNavData.name}-${name}`,
  }));

  const [selectedIndicator, selectIndicator] = useState(
    introductionOptions.name
  );

  return (
    <PartnershipLayout
      partnership={_partnership}
      seoMetaTags={copy.seoMetaTags}
      sector={sector}
      sideNavOptions={prepend(introductionOptions, sideNavOptions)}
      selectedIndicator={selectedIndicator}
      {...pageContext}
    >
      <Section id="introductie">
        <ImageInfoBlock node={<Img fluid={infoImage.childImageSharp.fluid} />}>
          <InView
            threshold={1}
            onChange={inView => {
              if (inView) {
                selectIndicator(introductionOptions.name);
              }
            }}
          >
            <Heading
              appearance="inherit"
              component="h1"
              size="xxl"
              weight="bold"
            >
              {infoBlockTitle}
            </Heading>
          </InView>
          <Text appearance="inherit" size="m">
            {infoBlockBody}
          </Text>
        </ImageInfoBlock>
      </Section>
      {(heading.trim() !== '' || lead.trim() !== '' || intro.trim() !== '') && (
        <Section>
          <Introduction title={heading} text={<>{parseTooltip(lead)}</>} />
          <Text size="m" appearance="base">
            {parseTooltip(intro)}
          </Text>
        </Section>
      )}
      {indicators.map(
        ({ name, Chart, width, height, xKey, ...chartProps }, index) => (
          <Section key={name} id={sideNavOptions[index].id}>
            <InView
              threshold={0.5}
              onChange={inView => {
                if (inView) {
                  selectIndicator(sideNavOptions[index].name);
                }
              }}
            >
              <Heading component="h1" size="xxl" appearance="secondary">
                {parseTooltip(copy[`${name}Title`])}
              </Heading>
              <Text size="m" appearance="base">
                {parseTooltip(copy[`${name}Body`])}
              </Text>
              <div></div>
              <CardToggle
                toggle={
                  <>
                    <Button
                      onClick={setSelectedView(name, 'overview')}
                      color={
                        selectedViews[name] === 'overview'
                          ? 'primary'
                          : 'secondary'
                      }
                      styling="toggle"
                      type="button"
                    >
                      <Icon size="lg" type="chartBar" />
                      <span>Overzicht</span>
                    </Button>
                    <Button
                      color={
                        selectedViews[name] === 'history'
                          ? 'primary'
                          : 'secondary'
                      }
                      styling="toggle"
                      type="button"
                      onClick={setSelectedView(name, 'history')}
                    >
                      <Icon size="lg" type="chartLine" />
                      <span>Historie</span>
                    </Button>
                  </>
                }
              />
              <SlideIntoView minHeight="250px">
                <Card>
                  <Heading component="h2" size="xl" appearance="secondary">
                    {chartTitleGenerator(name)}
                  </Heading>
                  {selectedViews[name] === 'overview' ? (
                    <Chart
                      data={getLatestYear(data[name]).filter(notNil)}
                      xKey={xKey}
                      // yTick={name === 'studentCount' ? StudentCountTick : false}
                      colors={colors[name]}
                      height={height}
                      width={width}
                      {...chartProps}
                    />
                  ) : (
                    <LineChart
                      showTitle={isMobile()}
                      data={map(
                        year => ({
                          ...reduce(
                            (acc, curr) => {
                              if (notNil(curr)) {
                                acc[curr[xKey]] = curr.values[0].value;
                              }
                              return acc;
                            },
                            {},
                            data[name][year]
                          ),
                          [xKey]: year.split('/')[0],
                        }),
                        reverse(
                          Object.keys(data[name]).filter(year =>
                            data[name][year].reduce((acc, curr) => {
                              if (isNil(curr) || acc === false) {
                                return false;
                              }
                              return acc;
                            }, true)
                          )
                        )
                      )}
                      height={height}
                      xKey="label"
                      // yTick={name === 'studentCount' ? StudentCountTick : false}
                      {...chartProps}
                    />
                  )}
                  <Legend
                    source={formatDataSource(dataSources[name])}
                    data={formatLegendItems(
                      getLatestYear(data[name]).filter(notNil),
                      name,
                      _partnership.name,
                      selectedViews[name]
                    )}
                  />
                </Card>
              </SlideIntoView>
              {_partnership[`${name}Explanation`] && (
                <Explanation
                  title="Toelichting"
                  text={_partnership[`${name}Explanation`]}
                />
              )}
            </InView>
          </Section>
        )
      )}
      <ThemeFooter
        sector={sector}
        slug={_partnership.slug}
        next={{
          label: 'Volgende hoofdstuk: Financiën',
          to: '/financien',
        }}
      />
    </PartnershipLayout>
  );
};

Students.propTypes = {
  pageContext: PropTypes.shape({
    dato: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string.isRequired,
      startIntroText: PropTypes.string,
    }).isRequired,
    partnership: PropTypes.shape({
      city: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      zipcode: PropTypes.string.isRequired,
      website: PropTypes.string.isRequired,
    }).isRequired,
    nationalParticipationRate: PropTypes.shape({
      data: PropTypes.shape({}).isRequired,
    }).isRequired,
    copy: PropTypes.shape({
      infoBlockTitle: PropTypes.string.isRequired,
      infoBlockBody: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      lead: PropTypes.string.isRequired,
      intro: PropTypes.string.isRequired,
      studentCountTitle: PropTypes.string.isRequired,
      studentCountBody: PropTypes.string.isRequired,
      participationRateTitle: PropTypes.string.isRequired,
      participationRateBody: PropTypes.string.isRequired,
      seoMetaTags: PropTypes.shape({}).isRequired,
    }).isRequired,
    sector: PropTypes.string.isRequired,
  }).isRequired,
};

export default Students;
